import img1 from "../assets/Follow us.png";
import img2 from "../assets/Leaderboard.png";
import img3 from "../assets/Sign up.png";

function EligibilitySection() {
  return (
    <section className="EligibilitySection">
      <h2>Airdrop Eligibility</h2>
      <div className="HowToContainer">
        <p className="animate-1">How to Participate</p>
        <p className="gray animate-1">
          Participating is easy! Follow the steps below to be eligible for the
          $PFC airdrop event'
        </p>
        <div className="boxes">
          <div className="box animate-2">
            <img src={img3} alt="" />
            <p className="greenText">Sign Up</p>
            <p className="gray">
              Register with your email and create your account.
            </p>
          </div>
          <div className="box animate-3">
            <img src={img1} alt="" />
            <p className="greenText">Engage</p>
            <p className="gray">
              Complete tasks like joining game rooms and referring friends to
              earn XPs.{" "}
            </p>
          </div>
          <div className="box animate-4">
            <img src={img2} alt="" />
            <p className="greenText">Level Up </p>
            <p className="gray">
              Climb the leaderboard by accumulating XP and boost your ranking.
            </p>
          </div>
        </div>
        {/* <p className="animate-5">Requirements</p> */}
        <p className="gray animate-5">
          For more details, check out our{" "}
          <b style={{ color: "#5284f1" }}>Airdrop Guide</b> (T&Cs apply)
        </p>
      </div>
    </section>
  );
}

export default EligibilitySection;
