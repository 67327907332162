import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import particleConfig from "./ParticleConfig";

const App = () => {
  const [init, setInit] = useState(false);

  // This effect should run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // Loading the slim engine for particles
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = () => {
    // console.log(container);
  };

  return (
    <>
      {init && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={particleConfig}
        />
      )}
    </>
  );
};

export default App;
