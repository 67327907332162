import { useRef } from "react";
import { usePageAnimation } from "./Components/PageAnimation";

import AboutSection from "./Components/AboutSection";
import BackedBy from "./Components/BackedBy";
import EligibilitySection from "./Components/EligibilitySection";
import FAQs from "./Components/FAQs";
import Footer from "./Components/Footer";
import HeroSection from "./Components/HeroSection";
import RoadMap from "./Components/RoadMap";
import Testimonials from "./Components/Testimonials";
import EarnXP from "./Components/EarnXP";

function App() {
  const mainRef = useRef();
  usePageAnimation(mainRef);

  return (
    <main ref={mainRef}>
      <HeroSection />
      <AboutSection />
      <EligibilitySection />
      <EarnXP />
      <RoadMap />
      <Testimonials />
      <FAQs />
      <BackedBy />
      <Footer />
    </main>
  );
}

export default App;
