import Logo from "../assets/newLogo 1.png";

export default function Footer() {
  return (
    <>
      <div className="footer-main-section header-container">
        <div className="footer-logo">
          <img className="logo" src={Logo} alt="logo" />
          {/* <p>
            Panenka FC PTE. LTD. (“Panenka FC”) is a company incorporated in
            Singapore with Company number 202402005K, and with registered office
            address at 10 Anson Road #33-10, Suite C, International Plaza
            Singapore 079903
          </p> */}

          <p style={{ marginTop: "20px" }}>Follow us</p>
          <div className="footer-icon-list" style={{ marginBottom: 20 }}>
            <a
              href="https://t.me/ThefanclubpanenkaFC"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-icon-inner"
            >
              <i className="bi bi-telegram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/panenkafc/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-icon-inner"
            >
              <i className="bi bi-linkedin"></i>
            </a>
            <a
              href="https://twitter.com/PanenkaFC90"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-icon-inner"
            >
              <i className="bi bi-twitter-x"></i>
            </a>
          </div>
        </div>
        {/* <div className="footer-legel-section">
            <p style={{ cursor: "pointer" }} onClick={() => setShowToS(true)}>
              Terms of service
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => setShowPrivacyPolicy(true)}
            >
              Privacy Policy
            </p>
          </div> */}
        <div className="footer-copyright-text">
          <p>
            Copyright © 2024 <span className="text-green">Panenka FC. </span>
            All rights reserved
          </p>
        </div>
      </div>
    </>
  );
}
