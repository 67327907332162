import AboutImg from "../assets/About FC.png";

function AboutSection() {
  return (
    <section className="AboutSection">
      <div className="half ">
        <img
          src={AboutImg}
          className="AboutSectionElem mouseTrack"
          alt="Panenka FC"
          width={"100%"}
          height={"auto"}
        />
      </div>
      <div className="half">
        <h2 className="AboutSectionElem">About $PFC Airdrop</h2>
        <p className="AboutSectionElem">
          At Panenka FC, we are building the most trusted Fantasy gaming
          experience for the Fans out there.
        </p>
        <p className="AboutSectionElem">
          We utilise smart contracts on a blockchain to automate and
          decentralise game room management and rewards distribution that
          ensures our game-rooms are immutable and transparently operated,
          eliminating any scope for rigging, manipulation and scams.
        </p>
        <p className="AboutSectionElem">
          The Panenka FC Airdrop campaign is designed to kickstart a fan-driven
          revolution in fantasy football by distributing $PFC tokens to early
          supporters and community members.
        </p>
      </div>
    </section>
  );
}

export default AboutSection;
