import roadmapImg1 from "../assets/RoadMap-img/RM-1.png";
import roadmapImg2 from "../assets/RoadMap-img/RM-2.png";
import roadmapImg3 from "../assets/RoadMap-img/RM-3.png";
import roadmapImg4 from "../assets/RoadMap-img/RM-4.png";
import roadmapImg5 from "../assets/RoadMap-img/RM-5.png";
import roadmapImg6 from "../assets/RoadMap-img/RM-6.png";
import roadmapImg7 from "../assets/RoadMap-img/RM-7.png";
import roadmapImg8 from "../assets/RoadMap-img/RM-8.png";
import roadmapImg9 from "../assets/RoadMap-img/RM-9.png";
import roadmapImg10 from "../assets/RoadMap-img/RM-10.png";
import roadmapImg11 from "../assets/RoadMap-img/RM-11.png";

function RoadMap() {
  return (
    <section className="RoadMap">
      <h2>Road Map</h2>
      <img src={roadmapImg1} alt="" />
      <img src={roadmapImg2} alt="" />
      <img src={roadmapImg3} alt="" /> 
      <img src={roadmapImg4} alt="" />
      <img src={roadmapImg5} alt="" />
      <img src={roadmapImg6} alt="" />
      <img src={roadmapImg7} alt="" />
      <img src={roadmapImg8} alt="" />
      <img src={roadmapImg9} alt="" />
      <img src={roadmapImg10} alt="" />
      <img src={roadmapImg11} alt="" />
    </section>
  );
}

export default RoadMap;
