import { useState } from "react";

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is the Panenka FC Airdrop?",
      answer:
        "The Panenka FC Airdrop is a token distribution event where fans can earn free PFC tokens by participating in simple tasks like signing up, following our social channels, and referring friends.",
    },
    {
      question: "How can I participate in the Airdrop?",
      answer: (
        <>
          <p>
            To participate, you need to follow Panenka FC on our official social
            media channels and complete the designated tasks.
          </p>
          <p>
            The airdrop is open to both existing fans and newcomers. Just make
            sure to meet the eligibility criteria.
          </p>
        </>
      ),
    },
    {
      question: "What are the eligibility requirements for the Airdrop?",
      answer: (
        <>
          <p>To be eligible, you must:</p>
          <ul>
            <li>Be a fan of football and Panenka FC.</li>
            <li>Follow all our official social media channels.</li>
            <li>
              Complete the required tasks, such as signing up and referring
              friends, joining game rooms and engaging with our community.
            </li>
          </ul>
        </>
      ),
    },
    {
      question:
        "Why do I need to follow Panenka FC on social media to participate?",
      answer: (
        <>
          <p>
            Follow us on our official social media channels, join our Telegram
            group, and subscribe to our newsletter to receive the latest
            updates, announcements, and exclusive content.
          </p>
          <p>
            You can check your progress through the leaderboard or referral
            page. Once all tasks are marked as completed, you’re all set to
            receive your PFC tokens.
          </p>
        </>
      ),
    },
    {
      question: "What types of wallets are supported for the Airdrop?",
      answer: (
        <p>Once you sign up, we automatically create a wallet for you.</p>
      ),
    },
    {
      question: "Can I participate in the Airdrop multiple times?",
      answer: (
        <p>
          No, each individual is allowed to participate once per airdrop event.
          Duplicate entries or multiple sign-ups from the same individual may
          result in disqualification.
        </p>
      ),
    },
  ];

  return (
    <section className="faq-container">
      <h2 style={{ textAlign: "center", marginBottom: 20 }}>FAQ Section</h2>
      <div className="faq-accordion">
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div
              className={`faq-question ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <i
                className={`icon ${
                  activeIndex === index ? "icon-up" : "icon-down"
                }`}
              ></i>
            </div>
            <div
              className={`faq-answer ${activeIndex === index ? "show" : ""}`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;
