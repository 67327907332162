import React from "react";
import seierLogo from "../assets/seier_logo.png";
import concordiumLogo from "../assets/concordium_logo.png";
import XvcLogo from "../assets/XVC_tech-logo-light.png";
import XdcLogo from "../assets/XDC_logo.jpg";
import FoundershipLogo from "../assets/Foundership_Logo.png";

function BackedBy() {
  return (
    <div className="support-main-section header-container">
      <h2
        className="section-title greenGradientTxt"
        style={{ textAlign: "center" }}
      >
        Backed by
      </h2>
      <div className="support-image-area">
        <div className="slide-track">
          {/* Repeat twice for seamless scrolling */}
          {[...Array(2)].map((_, i) => (
            <React.Fragment key={i}>
              <div className="slide">
                <a
                  href="https://www.seiercapital.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={seierLogo} alt="Seier Capital Logo" />
                </a>
              </div>
              <div className="slide">
                <a
                  href="https://www.concordium.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={concordiumLogo} alt="Concordium Logo" />
                </a>
              </div>
              <div className="slide">
                <a
                  href="https://www.xvc.tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={XvcLogo} alt="XVC Tech Logo" />
                </a>
              </div>
              <div className="slide">
                <a
                  href="https://xdc.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={XdcLogo} alt="XDC Logo" />
                </a>
              </div>
              <div className="slide">
                <a
                  href="https://foundershiphq.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={FoundershipLogo} alt="Foundership HQ Logo" />
                </a>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BackedBy;
