function EarnXP() {
  const xpObj = [
    {
      xp: 50,
      title: "Sign up & Verified Email",
    },
    {
      xp: 10,
      title: "Join Free Game Room",
    },
    {
      xp: 50,
      title: "Successful Referrals",
    },
    {
      xp: 100,
      title: "Join 10 Game Rooms",
    },
    {
      xp: 100,
      title: "Win a Game Room",
    },
  ];
  return (
    <section className="EarnXpSection">
      <h2>How to Earn XPs</h2>
      <div className="row">
        {xpObj.map((item, index) => (
          <div className={`xpBox box${index}`} key={index}>
            <h1>{item.xp} XP</h1>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default EarnXP;
