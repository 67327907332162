import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const usePageAnimation = (mainRef) => {
  useEffect(() => {
    const mainElement = mainRef.current;

    if (!mainElement) return;

    // Refresh ScrollTrigger positions
    const refreshTriggers = () => {
      ScrollTrigger.refresh();
    };

    window.addEventListener("load", refreshTriggers);
    window.addEventListener("resize", refreshTriggers);

    // hero section
    const heroTimeLine = gsap.timeline({ onComplete: refreshTriggers });

    heroTimeLine
      .fromTo(
        ".mouseTrack .logo",
        {
          opacity: 0,
          y: -30,
          scale: 50,
        },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
        }
      )
      .fromTo(
        ".mouseTrack h1",
        {
          opacity: 0,
          y: -30,
        },
        {
          opacity: 1,
          y: 0,
        }
      )
      .fromTo(
        ".mouseTrack p",
        {
          opacity: 0,
          y: -30,
        },
        {
          opacity: 1,
          y: 0,
        }
      )
      .fromTo(
        ".scroll-container i",
        {
          opacity: 0,
          y: -30,
        },
        {
          opacity: 1,
          y: 0,
        }
      );

    // section title
    gsap.utils.toArray("section h2 ").forEach((ele) => {
      gsap.fromTo(
        ele,
        {
          opacity: 0,
          y: -100,
          scale: 2,
        },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          ease: "none",
          scrollTrigger: {
            trigger: ele,
            start: "top 80%",
            end: "top 60%",
            toggleActions: "play none none none",
          },
          duration: 0.2,
        }
      );
    });

    // animate up animations
    gsap.utils
      .toArray(".AboutSectionElem , .faq-item, .support-main-section .slide ")
      .forEach((ele) => {
        gsap.fromTo(
          ele,
          {
            opacity: 0,
            y: -100,
          },
          {
            opacity: 1,
            y: 0,
            ease: "none",
            scrollTrigger: {
              trigger: ele,
              start: "top 80%",
              end: "top 60%",
              toggleActions: "play none none none",
            },
            duration: 0.2,
          }
        );
      });

    // footer animation
    gsap.fromTo(
      "footer-main-section",
      {
        opacity: 0,
        y: 200,
      },
      {
        opacity: 1,
        y: 0,
        ease: "none",
        scrollTrigger: {
          trigger: "footer-main-section",
          start: "top 80%",
          end: "top 60%",
          toggleActions: "play none none none",
        },
        duration: 0.2,
      }
    );

    // EligibilitySection ============
    gsap.fromTo(
      ".EligibilitySection .animate-1",
      {
        opacity: 0,
        x: -30,
        delay: 1,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".EligibilitySection .animate-1",
          start: "top 80%",
          end: "top 60%",
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      ".EligibilitySection .animate-2",
      {
        opacity: 0,
        x: -30,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".EligibilitySection .animate-2",
          start: "top 80%",
          end: "top 60%",
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      ".EligibilitySection .animate-3",
      {
        opacity: 0,
        y: 30,
      },
      {
        opacity: 1,
        y: 0,
        decay: 0,
        scrollTrigger: {
          trigger: ".EligibilitySection .animate-3",
          start: "top 80%",
          end: "top 60%",
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      ".EligibilitySection .animate-4",
      {
        opacity: 0,
        x: 30,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".EligibilitySection .animate-4",
          start: "top 80%",
          end: "top 60%",
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      ".EligibilitySection .animate-5",
      {
        opacity: 0,
        y: 30,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: ".EligibilitySection .animate-5",
          start: "top 80%",
          end: "top 60%",
          toggleActions: "play none none none",
        },
      }
    );

    // Earn XP section
    const isLargeScreen = window.matchMedia("(min-width: 800px)").matches;

    gsap.utils.toArray(".xpBox").forEach((ele, i) => {
      gsap.fromTo(
        ele,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          ease: "none",
          duration: 0.2,
          scrollTrigger: {
            trigger: ele,
            start: "top 80%",
            end: "top 60%",
            toggleActions: "play none none none",
          },
          delay: isLargeScreen ? (i + 0.1) * 0.3 : 0, // Apply delay only on screens wider than 800px
        }
      );
    });

    // RoadMap images animations
    gsap.utils.toArray(".RoadMap img").forEach((ele) => {
      gsap.fromTo(
        ele,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          ease: "none",
          duration: 0.3,
          scrollTrigger: {
            trigger: ele,
            start: "top 80%",
            end: "top 60%",
            toggleActions: "play none none none",
          },
        }
      );
    });

    // Testimonials
    gsap.utils.toArray(".Testimonials .Carousel ").forEach((ele) => {
      gsap.fromTo(
        ele,
        {
          opacity: 0,
          x: 30,
        },
        {
          opacity: 1,
          x: 0,
          ease: "none",
          scrollTrigger: {
            trigger: ele,
            start: "top 80%",
            end: "top 60%",
            toggleActions: "play none none none",
          },
          duration: 1,
        }
      );
    });

    //   end =======
  });
};
